<template>
  <v-row align="center" justify="center">
    <v-col cols="5">
      <v-card flat height="725" class="containerBorder1">
        <v-card-title flat class="primary--text text-h4 justify-center">
          {{ $t("cardHeader1") }}
        </v-card-title>
        <v-card class="overflow-y-auto" height="88%" flat>
          <v-row no-gutters class="justify-center">
            <v-col cols="12" v-for="(service, index) in $t('onlineService')" :key="index" align="center">
              <v-card flat width="95%" height="60" color="primary" class="mt-2">
                <v-row no-gutters class="fill-height" align="center">
                  <v-col cols="6" align="start">
                    <v-card-text class="white--text text-h6">
                      {{ service.serviceName }}
                    </v-card-text>
                  </v-col>
                  <v-col cols="3" align="center">
                    <v-btn @click="
                      openLink(service)
                      " color="secondary">
                      <v-icon left dark> mdi-web </v-icon>
                      {{ $t("link1") }}
                    </v-btn>
                  </v-col>
                  <v-col cols="3">
                    <v-btn @click="emailLink(service.serviceLink, service.serviceName)"><v-icon left
                        class="mr-2">mdi-email</v-icon>Email Link</v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-col>

    <v-col cols="5">
      <v-card flat height="725" class="containerBorder1">
        <v-card-title class="primary--text text-h4 justify-center">
          {{ $t("cardHeader2") }}
        </v-card-title>
        <v-row no-gutters class="justify-center">
          <v-col cols="12" align="center">
            <v-card-text class="text-h6">
              You can fill out the forms at kiosk located near Clerk's Office.
            </v-card-text>
            <v-btn color="primary" @click="viewLocation()" class="mb-6"><v-icon>mdi-map-marker</v-icon>Form Filling
              Kiosk Location</v-btn>
            <!-- <v-card-text class="text-h5">
              https://miottawa.fidlar.com/MIOttawa/Apex.WebPortal/
            </v-card-text> -->
            <!-- <v-btn color="secondary" tile @click="
              sendLink('https://miottawa.fidlar.com/MIOttawa/Apex.WebPortal/', 'Digital Forms');">
              <v-icon left dark> mdi-email </v-icon>
              {{ $t("link1") | trimLength }}
            </v-btn> -->
            <v-card-text class="text-h3"> {{ $t("alter") }} </v-card-text>
          </v-col>
          <v-col cols="6" align="center" class="mt-3">
            <v-img src="@/assets/qrcode.svg" width="260" contain></v-img>
          </v-col>
          <v-col cols="12" align="center">
            <v-card-text class="text-caption grey--text">
              Disclaimer: All forms are managed outside of this kiosk by Fidlar Technologies.
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import QrcodeVue from "qrcode.vue";
export default {
  name: "forms",
  data() {
    return {
      value: "https://miottawa.fidlar.com/MIOttawa/Apex.WebPortal/",
      size: 300,
    };
  },
  components: {
    QrcodeVue,
  },
  computed: {
    ...mapGetters([]),
  },
  watch: {},
  methods: {
    openLink(service) {
      console.log('Service to open: ', service)

      this.$store.commit("setTouchRequest", {
        module: "Online Services",
        action: "Open Link",
        response: service.serviceName,
        timeStamp: new Date(),
        requestType: "Touch",
      });
      window.open(service.serviceLink, '_blank')
    },
    emailLink(url, name) {
      this.$store.dispatch("avatarSpeak", "Please enter your Email ID to get this link.");
      this.$store.dispatch("openEmailViewer", {
        pdfType: "link",
        pdfUrl: url,
        pdfName: name,
      });
      this.$store.commit("setTouchRequest", {
        module: "Online Services",
        action: "Open Email Viewer",
        response: "name",
        timeStamp: new Date(),
        requestType: "Touch",
      });
    },
    viewLocation() {
      this.$store.dispatch('openImageViewer', {
        name: "Form Filling Kiosk",
        url: 'https://cms.mi-ottawa.arsconnect.com/uploads/service_Kiosk_e23ccaf1dd.gif',
        floor: 1,
        showQR: false,
        qrUrl: ""
      })
    }
  },
  filters: {
    trimLength(val) {
      console.log('Value at trim length: ', val.length)
      if (val.length < 15) {
        return val;
      } else {
        console.log('Coming in here', `${val.substring(0, 12)}...`)
        return `${val.substring(0, 12)}...`;
      }

    },
  },
  mounted() { },
};
</script>

<style>
.formfolder {
  border-radius: 15px !important;
}

.containerBorder1 {
  border-width: 2px;
  border-style: solid;
  border-color: #2c6b87 !important;
  border-radius: 8px !important;
}
</style>

<i18n>
  {
    "en": {
        "cardHeader1": "Ottawa County Online Services",
        "cardHeader2": "Forms: Marriage Application, Notary and CPL",
        "link1": "Open Link",
        "alter": "OR"
      },
    "es":{
        "cardHeader1": "Servicios en Línea",
        "cardHeader2": "Formas: Marriage Application, Notary and CPL",
        "link1": "Enlace abierto",
        "alter": "O"
      }
  }
  </i18n>
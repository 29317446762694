export default {
  state: {
    avatarLoaded: false,
    currentLanguage: "en",
    avatarSpeech: "",
    textBubbleText: "",
    isSpeaking: false,
    defaultBubbleText: false,
    count: 0,
    avatarLangCodes: null,
    avatarDetails: null,
  },
  getters: {
    getCurrentLanguage: (state) => state.currentLanguage,
    getAvatarSpeech: (state) => state.avatarSpeech,
    getTextBubbleText: (state) => state.textBubbleText,
    getIsSpeaking: (state) => state.isSpeaking,
    getAvatarLoaded: (state) => state.avatarLoaded,
    getDefaultBubbleText: (state) => state.defaultBubbleText,
    getAvatarLangCodes: (state) => state.avatarLangCodes,
    getAvatarDetails: (state) => state.avatarDetails,
  },
  mutations: {
    setCurrentLanguage(state, lang) {
      state.currentLanguage = lang;
    },
    setIsSpeaking(state, status) {
      state.isSpeaking = status;
    },
    setAvatarSpeech(state, speech) {
      state.avatarSpeech = speech;
    },
    clearAvatarSpeech(state) {
      state.avatarSpeech = "";
    },
    setTextBubbleText(state, text) {
      state.textBubbleText = text;
    },
    clearTextBubbleText(state) {
      state.textBubbleText = "";
    },
    setAvatarLoaded(state, status) {
      state.avatarLoaded = status;
    },
    setDefaultBubbleText(state, status) {
      state.defaultBubbleText = status;
    },
    setAvatarLangCodes(state, langCodeObj) {
      state.avatarLangCodes = langCodeObj;
    },
    setAvatarDetails(state, avatarObj) {
      state.avatarDetails = avatarObj;
    },
  },
  actions: {
    avatarSpeak({ getters, commit }, speech) {
      return new Promise((resolve, reject) => {
        if (speech === null || speech === "") return reject("Nothing to speak");
        commit("clearAvatarSpeech");
        setTimeout(() => {
          //commit('setAvatarSpeech', speech);
          commit("setTextBubbleText", speech);
          window.sayText(
            speech,
            getters.getAvatarLangCodes.voiceId,
            getters.getAvatarLangCodes.langId,
            getters.getAvatarLangCodes.engineId
          );
        }, 100);
        resolve("Speech request sent to SitePal");
      });
    },
    loadAvatar({ getters, state }) {
      if (state.count === 0) {
        state.count = 1;
        var sc = document.createElement("script");
        sc.type = "text/javascript";
        //sc.src = "//vhss-d.oddcast.com/vhost_embed_functions_v4.php?acc=" + this.getSitepalID + "&js=0";
        sc.src =
          "//vhss-d.oddcast.com/vhost_embed_functions_v4.php?acc=" +
          getters.getAvatarDetails.sitepalId +
          "&js=0";

        //sc.src = this.getSitepalID
        sc.onload = () => {
          setTimeout(() => {
            if (typeof AC_VHost_Embed == "function") {
              var script = document.createElement("script");
              script.type = "text/javascript";
              script.innerHTML =
                "AC_VHost_Embed(7295865,960,960,'',1,0," +
                getters.getAvatarDetails.avatarId +
                ",0,1,1,'RwZIxMafWux6BWjhauvuPFZP3UcJLcqh',0, '400|300|15|15|L|T|false|0|0|0|0|0|C|0|0|0|992')";
              document.getElementById("divVHSS").appendChild(script);
            }
          }, 0);
        };
        document.head.appendChild(sc);
      }
    },
    identifyAvatarLangCodes({ getters, commit }) {
      if (getters.getCurrentLanguage === "es-US") {
        commit("setAvatarLangCodes", getters.getAvatarDetails.langCodes.es);
      } else {
        commit("setAvatarLangCodes", getters.getAvatarDetails.langCodes.en);
      }
    },
    applicationRefereshScheduler({ dispatch, state }) {
      const currentDate = new Date();
      const hour = currentDate.getHours();
      console.log("Current time", hour);
      if (hour === 8) {
        location.reload();
        setTimeout(() => {
          dispatch("applicationRefereshScheduler");
        }, 3600000);
      } else {
        setTimeout(() => {
          dispatch("applicationRefereshScheduler");
        }, 3600000);
      }
    },
  },
};

<template>
  <div>
    <v-row class="mt-7" no-gutters align="center" justify="center">
      <v-col cols="12" align="center">
        <v-card height="675" class="containerBorder" flat width="95%">
          <v-row class="pa-5" no-gutters>
            <v-col v-for="(department, index) in $t('departments')" :key="index" class="pa-3" cols="4">
              <v-card height="95" flat color="primary"
                @click="viewMap(department.departmentMapName, department.departmentName, department.speech)">
                <v-row no-gutters class="fill-height" align="center">
                  <v-col cols="12" align="center">
                    <span class="headline white--text">
                      {{ department.departmentName | trimLength }}
                    </span>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
  
<script>
import { mapGetters } from "vuex";
import SimpleKeyboard from "@/components/SimpleKeyboard";

export default {
  name: "departments",
  components: {
    SimpleKeyboard,
  },
  data() {
    return {
      tab: 0,
      input: "",
      searchKeyword: "",
      searchBarIcon: "mdi-magnify",
      expand: false,
      cardHeight: 560,
    };
  },
  methods: {
    viewMap(mapName, name, speech) {
      this.$i18n.t("maps").forEach(mapper => {
        if (mapper.mapName.toLowerCase().trim() === mapName.toLowerCase().trim()) {
          var floorName = this.$i18n.t('floors').filter(floor => floor.floorNumber === mapper.mapFloor)
          this.$store.dispatch("openImageViewer", {
            name: name,
            url: mapper.mapImage,
            floor: floorName[0].floorName,
            showQR: false,
            qrUrl: ""
          });
          this.$store.dispatch("avatarSpeak", mapper.speech);
          this.tab = mapper.mapFloor - 1
        }
      })
      this.$store.commit("setTouchRequest", {
        module: "Departments",
        action: "Open Image Dialog",
        response: name,
        timeStamp: new Date(),
        requestType: "Touch",
      });
      //this.$store.dispatch("avatarSpeak", speech);
    },
  },
  filters: {
    trimLength(val) {
      if (val.length < 90) {
        return val;
      }
      return `${val.substring(0, 70)}...`;
    },
  },
  mounted() { },
};
</script>
  
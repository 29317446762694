import axios from "axios";
import router from "@/router";
import i18n from "../../i18n";
import { conforms } from "lodash";
export default {
  state: {
    qnaStatus: null,
    qnaKeyword: "",
    wrongAnswerCount: 0,
    court: "",
    faqCategory: "",
    isThinking: false,
    requestQna: true,
  },
  getters: {
    getQnaStatus: (state) => state.qnaStatus,
    getQnaKeyword: (state) => state.qnaKeyword,
    getWrongAnsCount: (state) => state.wrongAnswerCount,
    getCourt: (state) => state.court,
    getFaqCategory: (state) => state.faqCategory,
    getIsThinking: (state) => state.isThinking,
    getRequestQna: (state) => state.requestQna,
  },
  mutations: {
    setQnaStatus(state, status) {
      state.qnaStatus = status;
    },
    setQnaKeyword(state, keyword) {
      state.qnaKeyword = keyword;
    },
    setWrongAnswerCount(state, count) {
      state.wrongAnswerCount = count;
    },
    setCourt(state, court) {
      state.court = court;
    },
    setFaqCategory(state, category) {
      state.faqCategory = category;
    },
    setIsThinking(state, status) {
      state.isThinking = status;
    },
    setRequestQna(state, status) {
      state.requestQna = status;
    },
  },
  actions: {
    pullAllQnA({ commit }) {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        //GET {Endpoint}/language/query-knowledgebases/projects/{projectName}/qnas?api-version=2021-10-01
        url:
          "https://ars-studio-en.cognitiveservices.azure.com/language/query-knowledgebases/projects/" +
          i18n.t("qnaEndpoint").kbName +
          "/qnas?&api-version=2021-10-01&deploymentName=production",
        headers: {
          "Ocp-Apim-Subscription-Key": "da55d74bdec54b7b856aef2a8e8bbded",
          "Content-Type": "application/json",
        },
      };

      axios.request(config).then((response) => {
        console.log("Fetching all QNAS: ", response);
      });
    },
    gptIntegration({ dispatch, commit }, transcript) {
      commit("setTextBubbleText", "Thinking....");
      let data = JSON.stringify({
        question: transcript,
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://eoglxzylaixcpbhaoevg.supabase.co/functions/v1/qqna",
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVvZ2x4enlsYWl4Y3BiaGFvZXZnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDMxNzkzMTIsImV4cCI6MjAxODc1NTMxMn0.zh_eyNQClOQ6i00KJV4Xr0eVI4V2b6YyDW1GK3Tm3ME",
        },
        data: data,
      };
      axios.request(config).then((response) => {
        console.log("Output from GPT: ", response);
        dispatch("requestQnA", response.data.question);
      });
    },
    requestQnA({ getters, dispatch, commit }, transcript) {
      // Request to QNA
      if (getters.isThinking) {
        commit("setSpeechTranscript", transcript);
        commit("setTextBubbleText", transcript);
      }
      let data = JSON.stringify({
        top: 3,
        question: transcript,
        includeUnstructuredSources: true,
        confidenceScoreThreshold: "0.2",
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url:
          "https://ars-studio-en.cognitiveservices.azure.com/language/:query-knowledgebases?projectName=" +
          i18n.t("qnaEndpoint").kbName +
          "&api-version=2021-10-01&deploymentName=production",
        headers: {
          "Ocp-Apim-Subscription-Key": "da55d74bdec54b7b856aef2a8e8bbded",
          "Content-Type": "application/json",
        },
        data: data,
      };
      commit("setIsThinking", true);
      commit("setTextBubbleText", i18n.t("isThinking"));
      axios
        .request(config)
        .then((response) => {
          const qnaAnalytics = {
            timeStamp: new Date(),
            kioskID: getters.getKioskId,
            transcript: transcript,
            result: response.data,
          };
          commit("setTouchRequest", {
            module: "QnA",
            action: transcript,
            response: response.data.answers[0].answer,
            timeStamp: new Date(),
            requestType: "Speech",
          });
          //dispatch('sendQnaData', qnaAnalytics);
          dispatch("addQnaDatatoStrapi", qnaAnalytics);
          if (response.data.answers[0].confidenceScore > 0.6) {
            dispatch("generateAnswer", response.data.answers[0]);
          } else if (response.data.answers[0].confidenceScore > 0.45) {
            commit("setIsThinking", false);
            if (getters.getCurrentLanguage === "en") {
              dispatch(
                "avatarSpeak",
                "I didn't quite get that, did you mean to ask following questions. Please tap your preference."
              );
            } else {
              dispatch(
                "avatarSpeak",
                "No entendía eso, querías hacer las siguientes preguntas. Por favor, toque su preferencia."
              );
            }
            let followUp = [];
            response.data.answers.forEach((output) => {
              if (output.confidenceScore > 0.45)
                console.log(
                  "Follow up question when low on confidence: ",
                  output.questions
                );
              followUp.push(output.questions[0]);
            });
            var headerVal;
            if (getters.getCurrentLanguage === "en") {
              headerVal = "Please Tap the option you prefer";
            } else {
              headerVal = "Por favor, toque la opción que prefiera";
            }
            dispatch("openOptionsViewer", {
              dialogHeader: headerVal,
              dialogData: followUp,
            });
          } else {
            commit("setIsThinking", false);
            if (getters.getKioskProfile.data().telepresence) {
              commit("setTelepresenceViewer", true);
            } else {
              var mapPath = getters.getMaps.get(
                getters.getDefaultLocation.toLowerCase()
              );
              var floorName = i18n
                .t("floors")
                .filter((floor) => floor.floorNumber === mapPath.mapFloor);
              dispatch("openImageViewer", {
                name: mapPath.mapName,
                url: mapPath.mapImage,
                floor: floorName[0].floorName,
                showQR: false,
                qrUrl: "",
              });
              dispatch("avatarSpeak", i18n.t("defaultKBError"));
            }
          }
        })
        .catch((error) => {
          commit("setIsThinking", false);
          console.log(error);
        });
    },
    generateAnswer({ dispatch, commit, getters }, result) {
      console.log("What is the result: ", result);
      if (getters.getIsThinking) {
        commit("setQnaStatus", false);
        const metadata = result.metadata.intent;
        const ansObj = result;
        commit("setDefaultBubbleText", false);
        commit("closeOptionsViewer");
        switch (metadata) {
          case "navigate":
            var menuName = ansObj.metadata.navigateto;
            router.addRoute({
              path: menuName.toLowerCase(),
              component: () => import("@/views/" + menuName),
            });
            if (router.currentRoute.path !== menuName) {
              router.push(menuName).then(() => {
                commit("setQnaKeyword", "");
                dispatch("avatarSpeak", ansObj.answer);
                let page = i18n
                  .t("homepages")
                  .filter((page) => page.pageType[0].pathName === menuName);
                commit("setPageHeader", page[0].pageType[0].pageHeader);
              });
            } else {
              commit("setQnaKeyword", "");
              dispatch("avatarSpeak", ansObj.answer);
            }
            break;

          case "showmap":
            try {
              console.log("inside show map", i18n.t("maps"));
              var mapPath = i18n
                .t("maps")
                .filter(
                  (map) =>
                    map.displayPosition.toString() ===
                    ansObj.metadata.mapposition
                );
              console.log("Map Path: ", mapPath);
              var floorName = i18n
                .t("floors")
                .filter((floor) => floor.floorNumber === mapPath[0].mapFloor);
              console.log("Map Path floor Name: ", floorName);
              dispatch("openImageViewer", {
                name: mapPath[0].mapName,
                url: mapPath[0].mapImage,
                floor: floorName[0].floorName,
                showQR: false,
                qrUrl: "",
              });
              if (ansObj.answer.trim() !== "default") {
                dispatch(
                  "avatarSpeak",
                  ansObj.answer + " " + mapPath[0].speech
                );
              } else {
                dispatch("avatarSpeak", mapPath[0].speech);
              }
            } catch (e) {
              mapPath = getters.getMaps.get(
                getters.getDefaultLocation.toLowerCase()
              );
              floorName = i18n
                .t("floors")
                .filter((floor) => floor.floorNumber === mapPath.mapFloor);
              dispatch("openImageViewer", {
                name: mapPath.mapName,
                url: mapPath.mapImage,
                floor: floorName[0].floorName,
                showQR: false,
                qrUrl: "",
              });
              dispatch("avatarSpeak", i18n.t("mapNotFound"));
            }

            break;

          case "speak":
            dispatch("avatarSpeak", ansObj.answer);
            break;
          case "showmapqr":
            mapPath = i18n
              .t("maps")
              .filter(
                (map) =>
                  map.displayPosition.toString() === ansObj.metadata.mapposition
              );
            floorName = i18n
              .t("floors")
              .filter((floor) => floor.floorNumber === mapPath[0].mapFloor);
            var qrurl = ansObj.metadata.url;
            dispatch("openImageViewer", {
              name: mapPath[0].mapName,
              url: mapPath[0].mapImage,
              floor: floorName[0].floorName,
              showQR: true,
              qrUrl: "https://" + qrurl,
            });
            dispatch("avatarSpeak", ansObj.answer);

            break;
          case "showform":
            try {
              var formName = ansObj.metadata.find(
                ({ name }) => name === "formname"
              );
              getters.getFormsEnglish.forEach((form) => {
                if (form.formName.toLowerCase() === formName.value) {
                  dispatch("openPdfViewer", {
                    url: form.formPdf,
                    name: form.formName,
                    desc: "",
                    emailLink: form.pdfUrl,
                    type: "Form",
                  });
                  dispatch("avatarSpeak", ansObj.answer);
                }
              });
            } catch (e) {
              mapPath = getters.getMaps.get(
                getters.getDefaultLocation.toLowerCase()
              );
              floorName = i18n
                .t("floors")
                .filter((floor) => floor.floorNumber === mapPath.mapFloor);
              dispatch("openImageViewer", {
                name: mapPath.mapName,
                url: mapPath.mapImage,
                floor: floorName[0].floorName,
                showQR: false,
                qrUrl: "",
              });
              dispatch("avatarSpeak", i18n.t("formNotFound"));
            }

            break;
          case "searchbykeyword":
            commit("setQnaKeyword", "");
            router.addRoute({
              path: "/faqs",
              component: () => import("@/views/faqs"),
            });
            if (router.currentRoute.path !== "/faqs") {
              router.push("/faqs");
            }
            var page = i18n
              .t("homepages")
              .filter((page) => page.pageType[0].pathName === "faqs");
            commit("setPageHeader", page[0].pageType[0].pageHeader);
            var linkId = ansObj.metadata.linkid;
            commit("setQnaKeyword", linkId);
            dispatch("avatarSpeak", ansObj.answer);
            break;

          case "showfaqcategory":
            var faqKeyword = ansObj.metadata.keyword;

            router.addRoute({
              path: "/faqs",
              component: () => import("@/views/faqs"),
            });
            if (router.currentRoute.path !== "/faqs") {
              router.push("/faqs");
            }
            page = i18n
              .t("homepages")
              .filter((page) => page.pageType[0].pathName === "faqs");
            commit("setPageHeader", page[0].pageType[0].pageHeader);

            commit("setSelectedFaqCat", {
              name: faqKeyword,
              type: "expansionPanel",
            });
            dispatch("avatarSpeak", ansObj.answer);
            break;

          case "showformcategory":
            var formKeyword = ansObj.metadata.keyword;
            router.addRoute({
              path: "/forms",
              component: () => import("@/views/forms"),
            });
            if (router.currentRoute.path !== "/forms") {
              router.push("/forms");
            }
            commit("setPageHeader", "Forms");
            commit("setQnaKeyword", formKeyword);
            dispatch("avatarSpeak", ansObj.answer);
            break;

          case "showformpackets":
            var packetInfo = getters.getFormPacketsEnglish.get(
              ansObj.metadata.find(({ name }) => name === "keyword").value
            );
            dispatch("openPdfViewer", {
              url: packetInfo.packetFiles,
              name: packetInfo.packetName,
              desc: packetInfo.packetDesc,
              emailLink: packetInfo.packetLink,
              type: "Packet",
            });
            //dispatch('avatarSpeak', ansObj.answer);
            break;

          case "showemail":
            var url = ansObj.metadata.url;
            var docName = ansObj.metadata.name;
            dispatch("openEmailViewer", {
              pdfType: "link",
              pdfName: docName,
              pdfUrl: "https://" + url,
            });
            dispatch("avatarSpeak", ansObj.answer);
            break;

          case "followup":
            var prompts = ansObj.dialog.prompts;
            var followUp = [];
            dispatch("avatarSpeak", ansObj.answer);
            console.log(
              "Follow up question when metadata is followup: ",
              prompts
            );
            prompts.forEach((item) => followUp.push(item.displayText));
            dispatch("openOptionsViewer", {
              dialogHeader: i18n.t("selectOptionMsg"),
              dialogData: followUp,
            });
            break;
          default:
            mapPath = getters.getMaps.get(
              getters.getDefaultLocation.toLowerCase()
            );
            floorName = i18n
              .t("floors")
              .filter((floor) => floor.floorNumber === mapPath.mapFloor);
            dispatch("openImageViewer", {
              name: mapPath.mapName,
              url: mapPath.mapImage,
              floor: floorName[0].floorName,
              showQR: false,
              qrUrl: "",
            });
            dispatch("avatarSpeak", i18n.t("kbError"));
            break;
        }
      }
    },
  },
};

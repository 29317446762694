<template>
  <v-dialog v-model="getImageViewer.show" width="70%" persistent>
    <v-card width="100%">
      <v-row no-gutters>
        <v-col cols="12">
          <v-card-title>
            <v-row>
              <v-col align="center" cols="11">
                <span class="text-h5 pl-16 font-weight-bold">{{ getImageViewer.name }} - {{ getImageViewer.floor }}</span>
              </v-col>
              <v-col cols="1" align="center" class="pa-0">
                <v-progress-circular @click="$store.commit('closeImageViewer')" :rotate="180" :size="50" :width="7"
                  :value="closePercentage" color="error" class="my-2">
                  <v-icon large color="error"> mdi-close </v-icon>
                </v-progress-circular>
              </v-col>
            </v-row>
          </v-card-title>
          <v-divider class="mx-2"></v-divider>
          <v-row no-gutters v-if="!getImageViewer.showQR">
            <v-col cols="12" class="mb-10">
              <v-img :src="getImageViewer.url" width="98%"></v-img>
            </v-col>
          </v-row>
          <v-row v-else no-gutters>
            <v-col cols="8">
              <v-img :src="getImageViewer.url" width="98%"></v-img>
            </v-col>
            <v-col cols="1">
              <v-row no-gutters class="fill-height">
                <v-col cols="12" align="center">
                  <v-divider vertical></v-divider>
                </v-col>
                <v-col cols="12" align-self="center" align="center" class="font-weight-bold">
                  OR
                </v-col>
                <v-col cols="12" align="center">
                  <v-divider vertical></v-divider>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="3" align-self="center" class="d-flex justify-center">
              <v-card flat width="300" height="415" class="containerBorder1">
                <v-card-text class="font-weight-bold mb-0 pt-1">SCAN THE QR CODE TO GET THE LINK</v-card-text>
                <v-card-actions class="justify-center pa-0 ma-0">
                  <v-img width="90%" class="pa-0 ma-0" src="@/assets/pdqrcode.png"></v-img>
                </v-card-actions>
                <v-card-text class="px-1 font-weight-bold">{{ getImageViewer.qrUrl | trimLength }}</v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import QrcodeVue from 'qrcode.vue';
export default {
  name: "imageViewer",
  data() {
    return {
      closePercentage: 0,
      timeout: 0,
      intervalObj: 0,
      value: '',
      size: 225,
    };
  },
  components: {
    QrcodeVue
  },
  computed: {
    ...mapGetters(["getImageViewer", "getIsSpeaking"]),
  },
  watch: {
    getIsSpeaking(newVal) {
      if (!newVal) {
        clearTimeout(this.timeout);
        this.intervalObj = setInterval(() => {
          this.closePercentage += 20;
        }, 4000);
        this.timeout = setTimeout(() => {
          clearInterval(this.intervalObj);
          this.closePercentage = 0;
          this.$store.commit("closeImageViewer");
        }, 20000);
      } else {
        this.closePercentage = 0;
        clearInterval(this.intervalObj);
        clearTimeout(this.timeout);
      }
    },
  },
};
</script>

<style scoped></style>

import { db } from "@/firebase";
import i18n from "../../i18n";
export default {
    state: {
        contentFetched: false,
        appDimensions: {},
        pageHeader: null,
        pdfViewer: {},
        imageViewer: {},
        emailViewer: {},
        optionsViewer: {},
        courtTimingViewer: {},
        departmentServiceViewer: {},
        // user session handling (Displayed on Dashboard App)
        startUserSession: undefined,
        endUserSession: undefined,
        userQnaRequest: [],
        touchRequest: [],
        userSession: {},
        sessionEndViewer: false,
        telepresenceViewer: false,

        selectedFaqCat: {},
        selectedFormCat: {},

        // ADA Complaince
        enableADA: false,
        screenSaver: false
    },
    getters: {
        contentFetched: state => state.contentFetched,
        appDimensions: state => state.appDimensions,
        getPageHeader: state => state.pageHeader,
        getPdfViewer: state => state.pdfViewer,
        getImageViewer: state => state.imageViewer,
        getEmailViewer: state => state.emailViewer,
        getOptionsViewer: state => state.optionsViewer,
        getCourtTimingViewer: state => state.courtTimingViewer,
        getDepartmentServiceViewer: state => state.departmentServiceViewer,
        getTelepresenceViewer: state => state.telepresenceViewer,


        getStartUserSession: state => state.startUserSession,
        getEndUserSession: state => state.endUserSession,
        getTouchRequest: state => state.touchRequest,
        getUserQnaRequest: state => state.userQnaRequest,
        getSessionEndViewer: state => state.sessionEndViewer,

        getSelectedFaqCat: state => state.selectedFaqCat,
        getSelectedFormCat: state => state.selectedFormCat,

        getenableADA: state => state.enableADA,
        screenSaver: state => state.screenSaver
    },
    mutations: {
        setAppDimensions(state) {
            let newWidht = window.innerWidth;
            let newHeight = (1080 / 1920) * window.innerWidth;
            //proto: (original height / original width) x new width = new height
            // console.log(`New App Dimensions: (w X h) : (${newWidht} X ${newHeight})`);
            state.appDimensions = {
                width: newWidht,
                height: newHeight
            }
        },
        setPageHeader(state, value) {
            state.pageHeader = value;
        },
        setPdfViewer(state, pdfObj) {
            state.pdfViewer = pdfObj;
        },
        closePdfViewer(state) {
            state.pdfViewer = { show: false }
        },
        setImageViewer(state, imgObj) {
            state.imageViewer = imgObj
        },
        closeImageViewer(state) {
            state.imageViewer = { show: false }
        },
        setEmailViewer(state, emailObj) {
            state.emailViewer = emailObj
        },
        closeEmailViewer(state) {
            state.emailViewer = { show: false }
        },
        setOptionsViewer(state, optionsObj) {
            state.optionsViewer = optionsObj
        },
        closeOptionsViewer(state) {
            state.optionsViewer = { show: false }
        },
        setCourtTimingViewer(state, courtObj) {
            state.courtTimingViewer = courtObj
        },
        closeCourtTimingViewer(state) {
            state.courtTimingViewer = { show: false }
        },
        setDepartmentServiceViewer(state, departObj) {
            state.departmentServiceViewer = departObj
        },
        closeDepartmentServiceViewer(state) {
            state.departmentServiceViewer = { show: false }
        },
        setStartUserSession(state, startUserSession) {
            state.startUserSession = startUserSession
        },
        setEndUserSession(state, endUserSession) {
            state.endUserSession = endUserSession
        },
        setTouchRequest(state, touchRequest) {
            state.touchRequest.push(touchRequest)
        },
        setUserQnaRequest(state, userQnaRequest) {
            state.userQnaRequest.push(userQnaRequest)
        },
        setSessionEndViewer(state, status) {
            state.sessionEndViewer = status
        },
        setTelepresenceViewer(state, status) {
            state.telepresenceViewer = status
        },
        closeTelepresenceViewer(state) {
            state.telepresenceViewer = false
        },
        setSelectedFaqCat(state, faqCatObj) {
            state.selectedFaqCat = faqCatObj
        },

        setSelectedFormCat(state, formCatObj) {
            state.selectedFormCat = formCatObj
        },
        setEnableADA(state, status) {
            state.enableADA = status
        },
        setScreenSaver(state, status) {
            state.screenSaver = status
        }
    },
    actions: {
        openPdfViewer({ commit }, pdfObj) {
            pdfObj.show = true;
            commit('setPdfViewer', pdfObj)
        },
        openImageViewer({ commit }, imgObj) {
            imgObj.show = true
            commit('setImageViewer', imgObj)
        },
        openEmailViewer({ commit }, emailObj) {
            emailObj.show = true
            commit('setEmailViewer', emailObj)
        },
        openOptionsViewer({ commit }, optionsObj) {
            optionsObj.show = true
            commit('setOptionsViewer', optionsObj)
        },
        openCourtTimingViewer({ commit }, courtObj) {
            courtObj.show = true
            commit('setCourtTimingViewer', courtObj)
        },
        updateUserSession({ dispatch, state, getters }) {
            dispatch("addSessionDatatoStrapi", {
                kioskId: getters.getKioskId,
                sessionStartTime: getters.getStartUserSession,
                sessionEndTime: getters.getEndUserSession,
                userSession: JSON.stringify(getters.getTouchRequest),
                sessionEnded: true
            }).then(() => {
                state.touchRequest = []
                state.userQnaRequest = []
            })
            // db.collection('userSession').add({
            //     kioskID: getters.getKioskId,
            //     userSessionStartTime: getters.getStartUserSession,
            //     userSessionEndTime: getters.getEndUserSession,
            //     userSessionLogs: JSON.stringify(getters.getTouchRequest),
            //     //userQnaRequest: JSON.stringify(getters.getUserQnaRequest)
            // }).then(response => {
            //     state.touchRequest = []
            //     state.userQnaRequest = []
            // })
        },

        // Add all auto selections below
        setDefaults({ commit }) {
            commit('setSelectedFaqCat', { category: i18n.t('faqCategory')[0].categoryName });
            // commit('setSelectedFormCat', {
            //     category: i18n.t('formCategory')[0].categoryId
            // })
        },
        setDefaultsSpanish({ commit }) {
            commit('setSelectedFaqCat', { category: i18n.t('faqCategory')[0].categoryName });
            // commit('setSelectedFormCat', {
            //     category: i18n.t('formCategory')[0].categoryId
            // })
        }
    }
}
